
.font-face(@family, @path, @filename) {
    @version: 150625;

    @font-face {
        font-family: @family;
        src: url('../fonts/@{path}/@{filename}.woff2?@{version}') format('woff2'),
             url('../fonts/@{path}/@{filename}.woff?@{version}') format('woff');
        font-weight: normal;
        font-style: normal;
    }
}


.font-face('ACaslonBold', 'ACaslon-Bold', 'acaslon-bold-webfont');

@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700|Open+Sans:400,700,300,300italic,700italic);
