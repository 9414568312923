@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700|Open+Sans:400,700,300,300italic,700italic);
@font-face {
  font-family: 'ACaslonBold';
  src: url('../fonts/ACaslon-Bold/acaslon-bold-webfont.woff2?150625') format('woff2'), url('../fonts/ACaslon-Bold/acaslon-bold-webfont.woff?150625') format('woff');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: 'Open Sans';
  color: #000;
  font-size: 16px;
}
a {
  color: #bbb;
}
h2,
.h2 {
  font-family: 'Oswald';
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #fff;
}
h3,
.h3 {
  font-family: 'Oswald';
  font-weight: lighter;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 0;
  color: #fff;
}
